.App {
    text-align: center;
    min-width: 100vw;
    min-height: 100vh;
}

.item {
    margin-right: 10px;
    font-size: 30px;
}

.input {
    text-align: center !important;
    min-width: 500px;
    font-size: 24px;
}
